import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { format } from "utils/formatDate";
import { useEffect, useState } from "react";
import Service from "services/sales";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { findStatusPedido } from "./yupSchema";

export default function data(
  handleEdit,
  handleDelete,
  handleDetail,
  handleGerarNfe,
  handleDownloadNfe,
  handleGerarXmlNfe,
  handleCancelarNfe,
  handleDesvincularNfe,
  handleCorrigirNfe,
  handleFetchLogs,
  isAdmin = false,
  setLoading = () => {}
) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState("&sortBy=numPedido&sortOrder=-1");
  const [filterStr, setFilterStr] = useState(null);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(
      page,
      perPage,
      sortStr,
      filterStr
    );

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          ...row,
          id: row._id,
          numPedido:
            row.letterPedido?.length > 0
              ? row.numPedido + "-" + row.letterPedido
              : row.numPedido,
          data: format(new Date(row.data), "dd/MM/yyyy"),
          totalFinal: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.totalFinal),
          valorFrete: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.valorFrete),
          user: row?.user?.name,
          action: row,
          customer: row.customer?.razaoSocial || row.customer?.nomeFantasia,
          status: findStatusPedido(row.status),
        })
      )
    );

    setLoading(false);
  };

  const zerosBefore = (number, length) => {
    if (number === undefined || number == null) return number;

    const hasHifen = number.toString().includes("-");

    if (hasHifen) {
      const [num, letter] = number.toString().split("-");
      const numberString = num.toString();
      const zeros = "0".repeat(length);
      return `${zeros}${numberString}`.slice(-length) + "-" + letter;
    } else {
      const numberString = number.toString();
      const zeros = "0".repeat(length);
      return `${zeros}${numberString}`.slice(-length);
    }
  };

  const action = (props) => {
    const row = props?.row?.action;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Ações">
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            iconOnly
          >
            <Icon fontSize="small">more_vert</Icon>
          </MDButton>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {isAdmin && (
            <MenuItem
              onClick={() => {
                handleFetchLogs(row._id);
                handleClose();
              }}
            >
              Ver Logs
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleEdit(row._id);
              handleClose();
            }}
          >
            Editar
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDetail(row._id);
              handleClose();
            }}
          >
            Gerar PDF
          </MenuItem>
          {!row.nfe && (
            <MenuItem
              onClick={() => {
                handleGerarNfe(row);
                handleClose();
              }}
            >
              Gerar NFE
            </MenuItem>
          )}
          {row.nfe && (
            <MenuItem
              onClick={() => {
                handleDownloadNfe(row._id);
                handleClose();
              }}
            >
              Ver NFE
            </MenuItem>
          )}
          {row.nfe && (
            <MenuItem
              onClick={() => {
                handleGerarXmlNfe(row._id);
                handleClose();
              }}
            >
              Ver XML
            </MenuItem>
          )}
          {row.nfe && (
            <MenuItem
              onClick={() => {
                handleCancelarNfe(row._id);
                handleClose();
              }}
            >
              Cancelar NFE
            </MenuItem>
          )}
          {row.nfe && (
            <MenuItem
              onClick={() => {
                handleDesvincularNfe(row._id);
                handleClose();
              }}
            >
              Desvincular NFE
            </MenuItem>
          )}
          {row.nfe && (
            <MenuItem
              onClick={() => {
                handleCorrigirNfe(row._id, row.numPedido);
                handleClose();
              }}
            >
              Carta Correção NFE
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleDelete(row);
              handleClose();
            }}
          >
            Remover
          </MenuItem>
        </Menu>
      </MDBox>
    );
  };

  const updateRows = (rows) => {
    setRows(
      rows.map((row) =>
        Object({
          ...row,
          id: row._id,
          numPedido:
            row.letterPedido?.length > 0
              ? row.numPedido + "-" + row.letterPedido
              : row.numPedido,
          data: format(new Date(row.data), "dd/MM/yyyy"),
          totalFinal: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.totalFinal),
          valorFrete: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.valorFrete),
          user: row?.user?.name,
          action: row,
          customer: row.customer?.razaoSocial || row.customer?.nomeFantasia,
          status: findStatusPedido(row.status),
        })
      )
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr("&sortBy=numPedido&sortOrder=-1");
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      {
        headerName: "N. P",
        field: "numPedido",
        flex: 0.07,
        minWidth: matchesMd ? undefined : 60,
        renderCell: (props) => (
          <a
            href="javascript:;"
            onClick={() => handleEdit(props.row.id)}
            style={{
              fontWeight: 500,
              color: theme.palette.secondary.main,
            }}
          >
            {zerosBefore(props.row.numPedido, 4)}
          </a>
        ),
      },
      {
        headerName: "Cliente",
        field: "customer",
        flex: 0.3,
        minWidth: matchesMd ? undefined : 200,
      },
      {
        headerName: "Data",
        field: "data",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 110,
      },
      {
        headerName: "Valor Final",
        field: "totalFinal",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 110,
      },
      {
        headerName: "Vendedor",
        field: "user",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 110,
      },
      {
        headerName: "Valor Frete",
        field: "valorFrete",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 110,
      },
      {
        headerName: "Motorista",
        field: "motorista",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 110,
      },
      {
        headerName: "Veículo",
        field: "caminhao",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 110,
      },
      {
        headerName: "Média",
        field: "media",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 80,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 0.1,
      },
      { headerName: "Ação", field: "action", flex: 0.1, renderCell: action },
    ],

    rows,
    fetchData,
    updateRows,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
    sortStr,
    filterStr,
  };
}
