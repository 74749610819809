import { useState, useEffect } from "react";
import MDDialog from "components/MDDialog";
import MDButton from "components/MDButton";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "utils/formatDate";
import { commaToDot } from "utils/numberComma";
import { findStatusPedido, findTipoFrete, TIPO_FRETE } from "./yupSchema";

export default function HistoryModal({ open, handleClose, logs, users }) {
  const [selectedLog, setSelectedLog] = useState(null);

  const onClose = () => {
    setSelectedLog(null);
    handleClose?.();
  };

  const renderValue = (value, fieldName) => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "boolean") return value ? "Sim" : "Não";

    const actualValue =
      typeof value === "object" && value.hasOwnProperty("oldValue")
        ? value.oldValue
        : value;

    if (
      (fieldName.startsWith("data") || fieldName.startsWith("dt")) &&
      actualValue
    ) {
      return format(new Date(actualValue), "dd/MM/yyyy");
    }
    if (
      (fieldName === "totalFinal" ||
        fieldName === "valorTotal" ||
        fieldName === "valorFrete") &&
      actualValue
    ) {
      return actualValue
        ? typeof actualValue === "string" && actualValue.includes(",")
          ? Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(commaToDot(actualValue))
          : Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(actualValue)
        : actualValue;
    }
    if (fieldName === "tipoFrete" && actualValue) {
      return findTipoFrete(actualValue);
    }
    if (fieldName === "status" && actualValue) {
      return findStatusPedido(actualValue);
    }
    return actualValue;
  };

  const renderNewValue = (value, fieldName) => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "boolean") return value ? "Sim" : "Não";

    const actualValue =
      typeof value === "object" && value.hasOwnProperty("newValue")
        ? value.newValue
        : value;

    if (fieldName === "user" && actualValue) {
      return users.find((u) => (u.id = actualValue))?.name;
    }

    if (
      (fieldName.startsWith("data") || fieldName.startsWith("dt")) &&
      actualValue
    ) {
      return format(new Date(actualValue), "dd/MM/yyyy");
    }
    if (
      (fieldName === "totalFinal" ||
        fieldName === "valorTotal" ||
        fieldName === "valorFrete") &&
      actualValue
    ) {
      return actualValue
        ? typeof actualValue === "string" && actualValue.includes(",")
          ? Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(commaToDot(actualValue))
          : Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(actualValue)
        : actualValue;
    }
    if (fieldName === "tipoFrete" && actualValue) {
      return findTipoFrete(actualValue);
    }
    if (fieldName === "status" && actualValue) {
      return findStatusPedido(actualValue);
    }
    return actualValue;
  };

  const filterRelevantChanges = (data) => {
    const relevantFields = Object.entries(data).filter(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return (
          value.hasOwnProperty("oldValue") &&
          value.hasOwnProperty("newValue") &&
          value.oldValue !== value.newValue
        );
      }
      return false;
    });
    return Object.fromEntries(relevantFields);
  };

  const parseFieldName = (fieldName) => {
    const fieldMappings = {
      numPedido: "Número do Pedido",
      letterPedido: "Letra do Pedido",
      data: "Data",
      dataDescarga: "Data da Descarga",
      totalFinal: "Total Final",
      valorTotal: "Valor Total",
      valorFrete: "Valor do Frete",
      status: "Status",
      customer: "Cliente",
      user: "Vendedor",
      // Add more field mappings as needed
    };
    return fieldMappings[fieldName] || fieldName;
  };

  return (
    <MDDialog
      open={open}
      handleClose={onClose}
      title="Histórico de Alterações"
      maxWidth="lg"
      fullWidth
      actions={
        <MDButton variant="contained" color="info" onClick={onClose}>
          Fechar
        </MDButton>
      }
    >
      <TextField
        sx={{ mt: 2, mb: 2 }}
        select
        fullWidth
        label="Selecione a alteração"
        value={selectedLog?._id || ""}
        onChange={(e) => {
          const selected = logs.find((log) => log._id === e.target.value);
          setSelectedLog(selected);
        }}
        SelectProps={{
          native: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      >
        <option value="">Selecione...</option>
        {logs.map((log) => (
          <option key={log._id} value={log._id}>
            {`${format(new Date(log.createdAt), "dd/MM/yyyy HH:mm")} - ${
              log.user.name
            }`}
          </option>
        ))}
      </TextField>

      {selectedLog && (
        <div style={{ marginTop: 20 }}>
          <Table>
            <TableHead
              className="w-full"
              sx={{ width: "100%", display: "table-header-group" }}
            >
              <TableRow>
                <TableCell>Campo</TableCell>
                <TableCell>Valor Anterior</TableCell>
                <TableCell>Novo Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(
                filterRelevantChanges(selectedLog.changedData)
              ).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{parseFieldName(key)}</TableCell>
                  <TableCell>{renderValue(value, key)}</TableCell>
                  <TableCell>{renderNewValue(value, key)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </MDDialog>
  );
}
