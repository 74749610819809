import {
  Box,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import MDButton from "components/MDButton";
import EditarContaAPagar from "layouts/payables/components/editData";
import { findBillingStatus } from "layouts/payables/components/yupSchema";
import EditarContaAReceber from "layouts/receivables/components/editData";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ContasService from "services/accounts";
import ReportsService from "services/reports";
import { dotToComma } from "utils/numberComma";

import { DataGrid } from "@mui/x-data-grid";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR_DataGrid } from "utils/datagrid";
import { format } from "utils/formatDate";

export default function SaldoDiario() {
  const [dia, setDia] = useState(new Date());
  const [saldoDiario, setSaldoDiario] = useState(null);
  const [contas, setContas] = useState([]);
  const [extrato, setExtrato] = useState([]);
  const [extratoOriginal, setExtratoOriginal] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentIdPayable, setCurrentIdPayable] = useState(null);
  const [currentIdReceivable, setCurrentIdReceivable] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  async function fetchExtrato(changeDia = null, changeAccount = null) {
    setLoading(true);

    try {
      const data = await ReportsService.saldoDiario(changeDia);

      // Combina os pagamentos e recebimentos em uma única lista
      const combinedList = [
        ...data.pagamentosDoDia.map((pagamento) => ({
          ...pagamento,
          type: "Pagamento",
        })),
        ...data.recebimentosDoDia.map((recebimento) => ({
          ...recebimento,
          type: "Recebimento",
        })),
      ];

      // Ordena a lista combinada por data
      const sortedList = combinedList
        .sort((a, b) => new Date(a.dataPedido) - new Date(b.dataPedido))
        .map((item) => ({
          ...item,
          valor: dotToComma(item.valor.toFixed(2)),
          supplier: item.supplier
            ? item.supplier?.razaoSocial || item.supplier?.nomeFantasia
            : item.customer?.razaoSocial || item.customer?.nomeFantasia,
          status: findBillingStatus(item.status),
          account: item.account?.id,
          accountName: item.account?.nome,
          accountGroup: item.accountGroup?.descricao,
          accountSubGroup: item.accountSubGroup?.nome,
          dataVencimento: item.dataVencimento
            ? format(new Date(item.dataVencimento), "dd/MM/yyyy")
            : "",
          dataPagamento: item.dataPagamento
            ? format(new Date(item.dataPagamento), "dd/MM/yyyy")
            : "",
        }));

      setExtratoOriginal(sortedList);

      if (selectedAccount && selectedAccount !== "Todas") {
        const filteredList = sortedList.filter(
          (item) => item.account === selectedAccount
        );
        setExtrato(filteredList);
      } else {
        setExtrato(sortedList);
      }

      setSaldoDiario(data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar extrato:", error);
      setLoading(false);
    }
  }

  async function fetchContas() {
    const { data } = await ContasService.list();
    setContas(data);
  }

  const handleDateChange = async (date) => {
    setDia(date);
    if (!loading) fetchExtrato(date.toISOString());
  };

  const handleChangeAccount = async (e) => {
    setSelectedAccount(e.target.value);

    if (e.target.value && e.target.value !== "Todas") {
      const filteredList = extratoOriginal.filter(
        (item) => item.account === e.target.value
      );
      setExtrato(filteredList);
    } else {
      setExtrato(extratoOriginal);
    }
  };

  useEffect(() => {
    setSelectedAccount("Todas");
    fetchExtrato();
    fetchContas();
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        {currentIdPayable && (
          <EditarContaAPagar
            setShowEditState={() => setCurrentIdPayable(null)}
            currentIdAction={currentIdPayable}
            fetchData={() => fetchExtrato(dia.toISOString())}
          />
        )}

        {currentIdReceivable && (
          <EditarContaAReceber
            setShowEditState={() => setCurrentIdReceivable(null)}
            currentIdAction={currentIdReceivable}
            fetchData={() => fetchExtrato(dia.toISOString())}
          />
        )}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Saldo do Dia
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Saldo Diário ({format(dia, "dd/MM/yyyy")})
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <DatePicker
                      selected={dia}
                      onChange={handleDateChange} //only when value has changed
                      locale="ptBR"
                      dateFormat="dd/MM/yyyy"
                      customInput={<TextField style={{ paddingLeft: 4 }} />}
                    />

                    <TextField
                      id="conta-select"
                      name="conta"
                      label="Conta"
                      value={selectedAccount || ""}
                      onChange={handleChangeAccount}
                      select
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        minWidth: 200,
                        ml: 2,
                      }}
                    >
                      <option value="">Todas</option>
                      {contas.map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.nome}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </Box>
                {loading && (
                  <Typography variant="body1">Carregando...</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    mt: 2,
                  }}
                >
                  <Typography variant="h6">
                    Saldo Anterior: R${" "}
                    {dotToComma(Number(saldoDiario?.saldoAnterior).toFixed(2))}
                  </Typography>
                </Box>
                {!loading && (
                  <DataGrid
                    rows={extrato}
                    // empty
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                    columns={[
                      {
                        field: "dataPagamento",
                        headerName: "Recebimento / Pagamento",
                        flex: 1,
                      },
                      {
                        field: "supplier",
                        headerName: "Cliente / Fornecedor",
                        flex: 1,
                      },
                      {
                        field: "accountGroup",
                        headerName: "Centro de Custo",
                        flex: 1,
                      },
                      {
                        field: "accountSubGroup",
                        headerName: "Natureza de Lançamento",
                        flex: 1,
                      },
                      { field: "accountName", headerName: "Conta", flex: 1 },
                      { field: "descricao", headerName: "Descrição", flex: 1 },
                      { field: "type", headerName: "Tipo", flex: 1 },
                      { field: "status", headerName: "Status", flex: 1 },
                      {
                        field: "valor",
                        headerName: "Valor",
                        flex: 1,
                        align: "right",
                        renderCell: (params) => (
                          <Typography
                            style={{
                              color:
                                params.row.type === "Pagamento"
                                  ? "red"
                                  : "green",
                            }}
                          >{`${params.row.type === "Pagamento" ? "-" : "+"}R$${
                            params.row.valor
                          }`}</Typography>
                        ),
                      },
                      {
                        field: "actions",
                        headerName: "",
                        flex: 1,
                        renderCell: (params) => (
                          <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            type="submit"
                            onClick={
                              params.row.type === "Pagamento"
                                ? () => setCurrentIdPayable(params.row?._id)
                                : () => setCurrentIdReceivable(params.row?._id)
                            }
                            iconOnly
                          >
                            <Icon fontSize="small">edit</Icon>
                          </MDButton>
                        ),
                      },
                    ]}
                    pageSize={5}
                    componentsProps={{
                      cell: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    getRowId={(row) => row?._id}
                  />
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    mt: 2,
                  }}
                >
                  <Typography variant="h6">
                    Total Diário: R${" "}
                    {dotToComma(Number(saldoDiario?.saldoDiario).toFixed(2))}
                  </Typography>

                  <Typography variant="h6">
                    Total: R${" "}
                    {dotToComma(
                      Number(
                        saldoDiario?.saldoAnterior + saldoDiario?.saldoDiario
                      ).toFixed(2)
                    )}
                  </Typography>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
